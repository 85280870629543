<template>
  <v-container fluid class="content-wrapper" style="overflow-y: hidden">
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600 mb-0">{{ $t("SigninWeekend.title") }}</h4>
      <v-btn
        icon
        class="ml-3"
        @click="initAll()"
        :class="loadingData && 'animate__spining'"
        :disabled="loadingData"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>

    <!-- loading data -->
    <v-row v-if="loadingData" row wrap>
      <v-col cols="12">
        <div class="card card-shadow mb-0" style>
          <v-skeleton-loader type="article"></v-skeleton-loader>
        </div>
      </v-col>
    </v-row>

    <!-- show data -->
    <v-row v-else row wrap>
      <!-- <v-col cols="12">
        <div class="card card-shadow mb-0" style>
          <div class="card-header pb-0">
            <h5 class="fw-500">{{ $t("SigninWeekend.weekend") }}</h5>
            <h5 class="mb-0">
              -
              {{ $t("SigninWeekend.weekendfee") }}
            </h5>
          </div>
          <div class="card-body">
            <v-radio-group v-model="weekend">
              <v-radio
                :label="$t('SigninWeekend.dontwant')"
                :value="false"
                color="#1e9ff2"
              ></v-radio>
              <div class="d-flex flex-column align-start justify-start mt-3">
                <v-radio
                  :label="$t('SigninWeekend.want')"
                  :value="true"
                  color="#1e9ff2"
                ></v-radio>

                <div class="ml-8 mt-1 day-show flex-wrap d-flex" v-if="weekend">
                  <div
                    class="custom-control custom-checkbox mr-3"
                    v-for="item in itemsDays"
                    :key="item.value"
                  >
                    <input
                      :id="item.value"
                      name="days"
                      type="checkbox"
                      class="custom-control-input"
                      :value="item.value"
                      :checked="item.check"
                      @change="checkListDays(item)"
                    />
                    <label class="custom-control-label" :for="item.value">
                      {{ item.name }}
                    </label>
                  </div>
                </div>
              </div>
            </v-radio-group>
          </div>
        </div>
      </v-col> -->

      <v-col cols="12">
        <div class="card card-shadow" style>
          <div class="card-header pb-0">
            <h5 class="fw-500">{{ $t("SigninWeekend.holiday") }}</h5>
            <h5 class="mb-0">{{ $t("SigninWeekend.holidayfee") }}</h5>
          </div>
          <div class="alert alert-warning ml-6 mb-2 w-75 mt-3" role="alert">
            <v-icon small color="#963b00">mdi-alert-circle-outline</v-icon>
            {{ $t("SigninWeekend.holidayfee_warning") }}
          </div>
          <div class="card-body">
            <v-radio-group v-model="holiday_and_special">
              <v-radio
                :label="$t('SigninWeekend.dontwant')"
                :value="false"
                color="#1e9ff2"
              ></v-radio>
              <div class="d-flex flex-column align-start justify-start mt-3">
                <v-radio
                  :label="$t('SigninWeekend.want')"
                  :value="true"
                  color="#1e9ff2"
                ></v-radio>

                <h5 v-if="holiday_and_special" class="fw-500 ml-8 mt-3">
                  {{ $t("SigninWeekend.weekend") }}
                </h5>
                <div
                  class="ml-8 mt-1 day-show flex-wrap d-flex"
                  v-if="holiday_and_special"
                >
                  <div
                    class="custom-control custom-checkbox mr-3"
                    v-for="item in itemsDays"
                    :key="item.value"
                  >
                    <input
                      :id="item.value"
                      name="days"
                      type="checkbox"
                      class="custom-control-input"
                      :value="item.value"
                      :checked="item.check"
                      @change="checkListDays(item)"
                    />
                    <label class="custom-control-label" :for="item.value">
                      {{ item.name }}
                    </label>
                  </div>
                </div>

                <h5 v-if="holiday_and_special" class="fw-500 ml-8 mt-3">
                  {{ $t("SigninWeekend.holiday") }}
                </h5>
                <div
                  class="mt-1 d-flex flex-column"
                  style="width: 100%"
                  v-if="holiday_and_special"
                >
                  <v-radio-group row v-model="current_month" class="ml-8 mt-0">
                    <v-radio
                      :label="`${$t('SigninWeekend.month_now')}`"
                      :value="true"
                      color="#1e9ff2"
                      @change="checkSelectMonth('current')"
                    ></v-radio>

                    <div class="d-flex flex-wrap">
                      <v-radio
                        label
                        :value="false"
                        color="#1e9ff2"
                        class="mr-0"
                        @change="checkSelectMonth('select')"
                      ></v-radio>
                      <div class="mr-1 d-flex flex-column justify-end align-start">
                        <label>{{ $t("SigninWeekend.month_since") }}</label>
                        <input
                          type="month"
                          class="form-control mt-1"
                          v-model="start_month"
                          style="min-height: 2.625rem"
                          :disabled="!holiday_select_month"
                          :min="minMonth"
                          :max="start_month == end_month ? null : end_month"
                        />
                      </div>
                      <div class="mr-1 d-flex flex-column justify-end align-start">
                        <label>{{ $t("SigninWeekend.month_to") }}</label>
                        <input
                          type="month"
                          class="form-control mt-1"
                          v-model="end_month"
                          style="min-height: 2.625rem"
                          :disabled="!holiday_select_month"
                          :min="start_month"
                          :max="maxMonth"
                        />
                      </div>
                      <div class="d-flex flex-column justify-end align-start">
                        <button
                          type="button"
                          class="btn btn-primary btn-min-width mt-27px d-flex align-center justify-center"
                          style="height: 2.625rem"
                          @click="showSelectRangeMonth()"
                        >
                          {{ $t("SigninWeekend.btn_show") }}
                        </button>
                      </div>
                    </div>
                  </v-radio-group>

                  <v-row class="fill-height">
                    <!-- <v-col cols="12" sm="0" md="4" lg="4"></v-col> -->
                    <v-col
                      v-if="loading_calendar"
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      class="d-flex align-center justify-center"
                    >
                      <img
                        :src="require('@/assets/img/thumb/loading.gif')"
                        class="img-gif-loading"
                      />
                    </v-col>

                    <v-col
                      v-if="current_month && !loading_calendar"
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                    >
                      <v-sheet height="64">
                        <v-toolbar flat>
                          <v-toolbar-title v-if="$refs.calendar">{{
                            nameOfMonth($refs.calendar.title)
                          }}</v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </v-sheet>
                      <v-sheet height="600">
                        <v-calendar
                          ref="calendar"
                          v-model="focus"
                          color="primary"
                          :events="events"
                          :event-color="getEventColor"
                          :type="type"
                          @click:event="viewDay"
                          @click:day="viewDay"
                          @click:date="viewDay"
                        ></v-calendar>
                        <v-menu
                          v-model="selectedOpen"
                          :close-on-content-click="false"
                          :activator="selectedElement"
                          offset-x
                        >
                          <v-card color="grey lighten-4" min-width="350px" flat>
                            <v-toolbar :color="selectedEvent.color" dark>
                              <v-btn icon>
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                              <v-toolbar-title
                                v-html="selectedEvent.name"
                              ></v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-btn icon>
                                <v-icon>mdi-heart</v-icon>
                              </v-btn>
                              <v-btn icon>
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <span v-html="selectedEvent.details"></span>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                text
                                color="secondary"
                                @click="selectedOpen = false"
                                >{{ $t("SigninWeekend.btn_cancel") }}</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </v-sheet>
                    </v-col>

                    <v-col
                      v-else-if="!current_month && !loading_calendar"
                      v-for="item in itemSelectMonths"
                      cols="12"
                      sm="12"
                      :md="itemSelectMonths.lenght > 1 ? '6' : '12'"
                      :lg="itemSelectMonths.lenght > 1 ? '6' : '12'"
                      :key="item"
                    >
                      <v-sheet height="64">
                        <v-toolbar flat>
                          <v-toolbar-title v-if="item">{{
                            nameOfMonth(item)
                          }}</v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </v-sheet>
                      <v-sheet height="600">
                        <v-calendar
                          :ref="item"
                          :start="startMonthSelect(item)"
                          color="primary"
                          :events="events"
                          :event-color="getEventColor"
                          :type="type"
                          @click:event="viewDay"
                          @click:day="viewDay"
                          @click:date="viewDay"
                        ></v-calendar>
                        <v-menu
                          v-model="selectedOpen"
                          :close-on-content-click="false"
                          :activator="selectedElement"
                          offset-x
                        >
                          <v-card color="grey lighten-4" min-width="350px" flat>
                            <v-toolbar :color="selectedEvent.color" dark>
                              <v-btn icon>
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                              <v-toolbar-title
                                v-html="selectedEvent.name"
                              ></v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-btn icon>
                                <v-icon>mdi-heart</v-icon>
                              </v-btn>
                              <v-btn icon>
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <span v-html="selectedEvent.details"></span>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                text
                                color="secondary"
                                @click="selectedOpen = false"
                                >{{ $t("SigninWeekend.btn_cancel") }}</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </v-sheet>
                    </v-col>
                  </v-row>

                  <v-dialog v-model="dialogHoliday" persistent max-width="290">
                    <v-card>
                      <v-card-title class>
                        <h4 class>{{ $t("SigninWeekend.dialog_holiday") }}</h4>
                        <v-spacer />
                        <v-btn
                          v-if="specialHoliday || addHoliday"
                          @click="removeHolidayItems()"
                          icon
                          color="error"
                        >
                          <v-icon dark x-small>mdi-delete-outline</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-divider class="mt-0 pt-0" />
                      <v-card-text>
                        <div class="form-group d-flex justify-start align-center mb-2">
                          <div class="custom-control custom-checkbox">
                            <input
                              id="specialHoliday"
                              name="priceadjust"
                              type="checkbox"
                              class="custom-control-input"
                              :value="specialHoliday"
                              :checked="specialHoliday"
                              @change="checkSpecialHoliday()"
                            />
                            <label class="custom-control-label" for="specialHoliday">
                              {{ $t("SigninWeekend.dialog_special_holiday") }}
                            </label>
                          </div>
                        </div>
                        <div class="form-group d-flex justify-start align-center mb-2">
                          <div class="custom-control custom-checkbox">
                            <input
                              id="addHoliday"
                              name="priceadjust"
                              type="checkbox"
                              class="custom-control-input"
                              :value="addHoliday"
                              :checked="addHoliday"
                              @change="checkAddHoliday()"
                            />
                            <label class="custom-control-label" for="addHoliday">
                              {{ $t("SigninWeekend.dialog_add_holiday") }}
                            </label>
                          </div>
                        </div>
                        <div v-if="addHoliday">
                          <input
                            type="text"
                            id="addholiday_text"
                            class="form-control"
                            style="
                              min-height: 3rem;
                              color: #3b4781;
                              border: 1px solid #babfc7 !important;
                            "
                            :placeholder="$t('SigninWeekend.dialog_add_text')"
                            name
                            v-model="addHolidayText"
                            @focus="getfocus('addholiday_text')"
                            @change="getfocus('addholiday_text')"
                            @mouseleave="mouseOver('addholiday_text')"
                          />
                        </div>
                      </v-card-text>
                      <v-divider class="mt-0 pt-0" />
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#6b6f82" outlined @click="closeDialogHoliday()">
                          <span style="font-size: 0.7rem !important">
                            {{ $t("SigninWeekend.dialog_close") }}
                          </span>
                        </v-btn>
                        <v-btn color="#666ee8" outlined @click="saveHolidayItems()">
                          <span style="font-size: 0.7rem !important">
                            {{ $t("SigninWeekend.dialog_confirm") }}
                          </span>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
            </v-radio-group>
            <div class="row">
              <div class="col-md-12">
                <!-- <div
                  class="btn-data-table btn-secondary btn-glow"
                  @click="gobackFunc()"
                >
                  {{ $t("SigninWeekend.btn_back") }}
                </div>-->
                <div
                  class="btn-data-table btn-primary btn-glow ml-3"
                  @click="saveWeekend()"
                >
                  {{ $t("SigninWeekend.btn_save") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import moment from "moment";

export default {
  name: "SigninWeekendAndHolidayScreen",
  metaInfo: {
    title: "Weekend & Holiday",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    var changeValue = this.checkChangeData();

    if (changeValue) {
      const answer = window.confirm(this.$t("Alert.needtogootherroute"));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else next();
  },
  data: () => ({
    moment: moment,
    userToken: "",
    propertyID: "",
    propertyData: null,
    value_element: "",
    weekend: null,
    holiday_and_special: null,
    holiday_and_special_value: false,
    itemsDays: [],
    items_holiday_and_special: [],
    select_date: null,

    focus: "",
    type: "month",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: ["#e8107f", "#1e9ff2"],
    names: [],
    dialogHoliday: false,
    specialHoliday: null,
    addHoliday: null,
    addHolidayText: "",
    addHolidayType: null,
    holiday_select_month: null,
    current_month: true,
    start_month: null,
    end_month: null,
    itemSelectMonths: [],

    isSavingData: true,
    loading_calendar: false,
    saveSuccess: false,
    loadingData: false,
  }),
  mounted() {
    this?.$refs?.calendar?.checkChange();
    EventBus.$on("refreshRightPhoto", this.initAll);
  },
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.initAll();
  },
  computed: {
    minMonth() {
      const self = this;
      if (self.end_month) {
        return moment(self.end_month).subtract(5, "month").format("YYYY-MM");
      } else return moment().subtract(5, "month").format("YYYY-MM");
    },
    maxMonth() {
      const self = this;
      if (self.start_month) {
        return moment(self.start_month).add(5, "month").format("YYYY-MM");
      } else return moment().add(5, "month").format("YYYY-MM");
    },
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    initAll() {
      const self = this;
      self.itemsDays = [
        {
          value: "monday",
          name: self.$t("SigninWeekend.monday"),
          check: false,
        },
        {
          value: "tuesday",
          name: self.$t("SigninWeekend.tuesday"),
          check: false,
        },
        {
          value: "wednesday",
          name: self.$t("SigninWeekend.wednesday"),
          check: false,
        },
        {
          value: "thursday",
          name: self.$t("SigninWeekend.thursday"),
          check: false,
        },
        {
          value: "friday",
          name: self.$t("SigninWeekend.friday"),
          check: false,
        },
        {
          value: "saturday",
          name: self.$t("SigninWeekend.saturday"),
          check: false,
        },
        {
          value: "sunday",
          name: self.$t("SigninWeekend.sunday"),
          check: false,
        },
      ];

      self.start_month = moment().format("YYYY-MM");
      self.end_month = moment().format("YYYY-MM");
      self.initPropertyData();
      self.names = [self.$t("SigninWeekend.dialog_holiday")];
    },
    gobackFunc() {
      this.loading = true;
      EventBus.$emit("goBackPage");
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      self.items_holiday_and_special = [];
      self.loadingData = true;
      EventBus.$emit("loadingtillend");
      self.loading_calendar = false;
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.propertyData = temp;
        self.weekend = temp?.weekend_and_holiday_rate?.weekend?.enable || false;
        temp?.weekend_and_holiday_rate?.weekend?.value?.map((el) => {
          const data = self.itemsDays?.find((p) => p.value == el);
          if (data?.value) data.check = true;
          else data.check = false;
        });
        self.holiday_and_special =
          (temp?.weekend_and_holiday_rate?.weekend?.enable &&
            temp?.weekend_and_holiday_rate?.holiday_and_special?.enable) ||
          false;

        temp?.weekend_and_holiday_rate?.holiday_and_special?.value?.map((el) => {
          const splitDate = el?.date?.split("T") || el?.split("T");
          const found = self?.items_holiday_and_special?.some(
            (p) => (p?.date == splitDate[0] || p?.date == el?.date) && p?.type == el?.type
          );
          if (!found)
            self.items_holiday_and_special.push({
              name: el?.name || self.$t("SigninWeekend.label_special_holiday"),
              date: splitDate[0] || el?.date,
              type: el?.type || "special_holiday",
            });
        });
        if (self.items_holiday_and_special?.length) {
          self.current_month = false;
          self.holiday_select_month = true;
          self.start_month = moment().format("YYYY-MM");
          self.end_month = moment().add(5, "month").format("YYYY-MM");
          self.showSelectRangeMonth();
        } else {
          self.current_month = true;
          self.holiday_select_month = null;
        }
        self.closeDialogHoliday();
        self.refreshCalendarData();
        self.loadingData = false;
      }
    },
    checkListDays(item) {
      const self = this;
      if (item.check) item.check = !item.check;
      else item.check = true;
    },
    async saveWeekend() {
      const self = this;
      self.loading_calendar = true;
      self.saveSuccess = false;

      var tempDays = [];
      self?.itemsDays?.map((el) => {
        if (el.check) tempDays.push(el.value);
      });

      var tempDays = [];
      self?.itemsDays?.map((el) => {
        if (el.check) tempDays.push(el.value);
      });

      const obj = {
        weekend: {
          enable: self.holiday_and_special,
          value: tempDays,
        },
        holiday_and_special: {
          enable: self.holiday_and_special,
          value: self.items_holiday_and_special,
        },
      };

      // console.log("obj: ", obj, self.isSavingData);

      if (self.holiday_and_special !== null) {
        try {
          const res = await self.axios.put(
            process.env.VUE_APP_API +
              `/property/weekend_and_holiday_rate?property_id=${self?.propertyID}`,
            obj,
            {
              headers: {
                Authorization: self?.userToken,
                "X-Language-Code":
                  localStorage?.getItem("language") || self?.$store?.state?.language,
              },
            }
          );
          if (res?.status == 200 && self.isSavingData) {
            self.saveSuccess = true;
            swal(
              self.$t("Alert.success_title"),
              self.$t("Alert.save_success"),
              self.$t("Alert.success_label"),
              {
                button: false,
                timer: 2000,
              }
            ).then(() => (self.loading_calendar = false));
          }
        } catch (error) {
          self.loading_calendar = false;
          console.log(error);
        } finally {
          self.loading_calendar = false;
          // self.initAll();
          // window.location.reload();
          await self.refreshCalendarData();
        }
      } else {
        self.loading_calendar = false;
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    viewDay({ date }) {
      const self = this;
      self.specialHoliday = null;
      self.addHoliday = null;

      const findData = self.items_holiday_and_special.filter((p) => p.date == date);
      self.select_date = date;
      self.dialogHoliday = true;
      findData?.map((el) => {
        self.addHolidayText = el?.name || "";
        self.addHolidayType = el?.type || "special_holiday";
        if (el?.type == "special_holiday") {
          self.specialHoliday = true;
        }
        if (el?.type == "holiday") {
          self.addHoliday = true;
        }
      });
    },
    getEventColor(event) {
      return event.color;
    },
    async updateRange({ start, end }) {
      const self = this;
      var events = [];
      self.events = [];

      try {
        await self?.items_holiday_and_special?.map((el) => {
          if (el) {
            const min = new Date(`${el.date}T00:00:00`).getTime();
            const max = new Date(`${el.date}T23:59:59`).getTime();
            const allDay = self.rnd(0, 3) === 0;
            const firstTimestamp = self.rnd(min, max);
            const first = new Date(firstTimestamp - (firstTimestamp % 900000));

            events.push({
              // name: self.names[self.rnd(0, self.names.length - 1)],
              name:
                el.type == "holiday"
                  ? el.name
                  : self.$t("SigninWeekend.label_special_holiday"),
              start: el.date,
              end: el.date,
              color:
                el.type == "holiday"
                  ? self.colors[0]
                  : self.colors[self.colors.length - 1],
              timed: allDay,
            });
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        self.events = events;
      }
    },
    async refreshCalendarData() {
      const self = this;
      // EventBus.$emit("loadingtillend");
      if (self.itemSelectMonths?.length)
        self.itemSelectMonths?.map((el) => {
          self.updateRange(
            moment(el).format("YYYY-MM-01"),
            moment(el).format("YYYY-MM-31")
          );
        });
      else self.updateRange(moment().format("YYYY-MM-01"), moment().format("YYYY-MM-31"));
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${
            a.getMonth() + 1
          }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
    checkSpecialHoliday() {
      const self = this;
      if (self.specialHoliday) self.specialHoliday = !self.specialHoliday;
      else {
        self.specialHoliday = true;
        if (!self.addHoliday) self.addHoliday = true;
      }
    },
    checkAddHoliday() {
      const self = this;
      if (self.addHoliday) self.addHoliday = !self.addHoliday;
      else self.addHoliday = true;
    },
    closeDialogHoliday() {
      const self = this;
      self.dialogHoliday = false;
      self.specialHoliday = null;
      self.addHoliday = null;
      self.addHolidayText = "";
      self.addHolidayType = null;
      self.select_date = null;
      self.isSavingData = true;
    },
    checkSelectMonth(value) {
      const self = this;
      if (value == "current") self.holiday_select_month = !self.holiday_select_month;
      else self.holiday_select_month = true;
    },
    nameOfMonth(month) {
      return moment(month).format("MMMM YYYY");
    },
    startMonthSelect(month) {
      return moment(month).format("YYYY-MM-DD");
    },
    showSelectRangeMonth() {
      const self = this;
      var dateStart = moment(self.start_month);
      var dateEnd = moment(self.end_month);
      var timeValues = [];

      while (dateEnd > dateStart || dateStart.format("M") === dateEnd.format("M")) {
        timeValues.push(dateStart.format("YYYY-MM"));
        dateStart.add(1, "month");
      }

      self.itemSelectMonths = timeValues;
      // console.log("month: ", self.itemSelectMonths);
    },
    saveHolidayItems() {
      const self = this;
      var temp = [];
      temp = self.items_holiday_and_special;
      if (self.specialHoliday) {
        const found = self.items_holiday_and_special.some(
          (p) => p.date == self.select_date && p.type == "special_holiday"
        );
        if (!found)
          temp.push({
            name: self.$t("SigninWeekend.label_special_holiday"),
            date: self.select_date,
            type: "special_holiday",
          });
      } else {
        const findData = self.items_holiday_and_special.find(
          (p) => p.date == self.select_date && p.type == "special_holiday"
        );
        if (findData) {
          const findIndex = self.items_holiday_and_special.findIndex(
            (p) => p.date == self.select_date && p.type == "special_holiday"
          );
          temp.splice(findIndex, 1);
        }
      }
      if (self.addHoliday) {
        const found = self.items_holiday_and_special.some(
          (p) => p.date == self.select_date && p.type == "holiday"
        );
        const indexFound = self.items_holiday_and_special.findIndex(
          (p) => p.date == self.select_date && p.type == "holiday"
        );
        if (!found)
          temp.push({
            name: self.addHolidayText || self.$t("SigninWeekend.label_holiday"),
            date: self.select_date,
            type: "holiday",
          });
        else
          self.items_holiday_and_special[indexFound] = {
            name: self.addHolidayText || self.$t("SigninWeekend.label_holiday"),
            date: self.select_date,
            type: "holiday",
          };
      } else {
        const findData = self.items_holiday_and_special.find(
          (p) => p.date == self.select_date && p.type == "holiday"
        );
        if (findData) {
          const findIndex = self.items_holiday_and_special.findIndex(
            (p) => p.date == self.select_date && p.type == "holiday"
          );
          temp.splice(findIndex, 1);
        }
      }
      self.items_holiday_and_special = temp;
      self.saveWeekend();
      self.closeDialogHoliday();
    },
    removeHolidayItems() {
      const self = this;
      var temp = [];
      temp = self.items_holiday_and_special;
      const findData = self.items_holiday_and_special.filter(
        (p) => p.date == self.select_date
      );
      findData?.map((el) => {
        const findIndex = self.items_holiday_and_special.findIndex(
          (p) => p.date == self.select_date
        );
        temp.splice(findIndex, 1);
      });
      self.items_holiday_and_special = temp;
      self.isSavingData = false;
      self.saveWeekend().then(() => {
        self.closeDialogHoliday();
        self.loading_calendar = false;
      });
    },
    checkChangeData() {
      const self = this;
      var cantleavethispage = false;
      var tmpItemsDay = [];
      self.itemsDays?.map((el) => {
        if (el.check) {
          tmpItemsDay.push(el.value);
        }
      });

      if (self.saveSuccess) {
        cantleavethispage = false;
      } else if (
        self.weekend !== self.propertyData.weekend_and_holiday_rate.weekend.enable ||
        self.holiday_and_special !==
          self.propertyData.weekend_and_holiday_rate.holiday_and_special.enable
      )
        cantleavethispage = true;
      else if (self.weekend) {
        tmpItemsDay?.map((el) => {
          const mapSameValue = self.propertyData.weekend_and_holiday_rate.weekend?.value?.find(
            (p) => p == el
          );
          // console.log('days: ', el, mapSameValue)
          if (!mapSameValue) return (cantleavethispage = true);
        });
      } else if (self.holiday_and_special) {
        self.items_holiday_and_special?.map((el) => {
          const mapSameValue = self.propertyData.weekend_and_holiday_rate.holiday_and_special?.value?.find(
            (p) => p?.date?.split("T") == el.date || p?.date?.split("T") == el.date
          );
          // console.log('holiday: ', mapSameValue)
          if (!mapSameValue) return (cantleavethispage = true);
        });
      } else cantleavethispage = false;
      return cantleavethispage;
    },
  },
};
</script>

<style scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";

.card-header {
  background-color: #fff;
  border-bottom: none;
}
</style>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";

.border-pink-focus {
  border-color: var(--pink) !important;
}
</style>
